<template>
  <div>
    <h3>Add Property Amenity</h3>
    <div class="row">
      <div class="col-md-7">
        <div class="card mt-3">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>Name</label>
                <input type="text" placeholder="Name" v-model="amenity.name" required class="form-control">
              </div>
              <div class="form-group text-end">
                <button class="btn btn-theme"><i class="lni lni-save me-2"></i>Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      amenity: {}
    }
  },

  methods: {
    submit() {
      this.axios.post('/api/v1/admin/amenities', this.amenity).then(() => {
        this.$router.push({ name: 'admin.amenities.index' })
        this.$swal({
          title: "Created",
          text: "The item has been created",
          type: 'success'
        })
      })
    }
  }
}
</script>